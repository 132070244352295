
import './App.css';
import Login from './pages/Login';
import { useState, useEffect } from 'react';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Home from './pages/Home';
import VerifyOTP from './pages/VerifyOTP';
import Account from './pages/Account';
import Reports from './pages/Reports';
import PledgeOrUnpledge from './pages/PledgeOrUnpledge';
import Contacts from './pages/Contacts';
import ContactView from './pages/ContactView';
import { checkUserSessionURL } from './utils/Urls';
import axios from 'axios'
import { header } from './utils/Request';

import toast, { Toaster } from 'react-hot-toast';
import Profile from './pages/Profile';
import LoaderScreen from './components/Loader';

function App() {
  const [isUserLoggedIn, setUserLoggedIn] = useState()
  useEffect(() => {
    // let number = localStorage.getItem("mobileNumber")
    // if(number !== null){
    //   setUserLoggedIn(true)

    // }else{
    //   setUserLoggedIn(false)
    // }

    checkUserSession()

  }, [])

  const checkUserSession = async () => {
    try {



      let data = await axios.post(checkUserSessionURL, {
        "phone_number": "hello",
      }, {
        withCredentials: true,
        headers: header
      })
      // console.log(data)

      if (data.data.status === "success") {

        setUserLoggedIn(true)
      } else {

        window.location.href = "https://neuron.adityatrading.in/subbroker/login"
      }
    } catch (error) {
      console.log(error)
      window.location.href = "https://neuron.adityatrading.in/subbroker/login"
    }
  }

  return (
    <div>
      <Toaster />
      <Router>

        <Switch>
          <Route path="/" exact component={isUserLoggedIn ? Home : LoaderScreen} />
          <Route path="/verifyOTP" exact component={VerifyOTP} />
          <Route path="/account" exact component={Account} />
          <Route path="/reports" exact component={Reports} />
          <Route path="/pledgeorunpledge" exact component={PledgeOrUnpledge} />
          <Route path="/contacts" exact component={Contacts} />
          <Route path="/contactview/:clientId" exact component={ContactView} />
          <Route path="/profile" exact component={Profile} />

        </Switch>
      </Router>
    </div>
  );
}

export default App;
