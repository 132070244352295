import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router'
import Loader from "react-loader-spinner";

export default function LoaderScreen() {

    return (
        <div className='flex justify-content-center'>
            
            <Loader
                type="ThreeDots"
                color="#00e676"
                height={60}
                width={60}//3 secs
              />
        </div>
    )
}
