import React, { useEffect, useState } from 'react';
import NavBar from '../components/NavBar';
import axios from 'axios';
import Select from 'react-select';
import { baseUrl, getReferralURL, getProfileURL, pledgeHoldingsUrl, getRemeshireBrokerageURL } from '../utils/Urls';
import { header } from '../utils/Request';
import Dropdown from '../components/Dropdown'; // Import the Dropdown component

export default function PledgeOrUnpledge() {
    const [userData, setUserData] = useState();
    const [wallet, setWallet] = useState(0);
    const [referralLink, setReferralLink] = useState();
    const [debitAmount, setDebitAmount] = useState(0);
    const [availableAmount, setAvailableAmount] = useState(0);

    const [kycDashboardUrl, setkycDashboardUrl] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [phoneNumber, setPhoneNumber] = useState();

    const [clientHoldingsData, setClientHoldingsData] = useState([]);
    const [subbrokerIds, setSubbrokerIds] = useState([]);
    const [selectedClientCode, setSelectedClientCode] = useState(null);

    useEffect(() => {
        getData();
        getProfile();
        getSubbrokerIds();
        // getHoldingsForPledgeAndUnpledge();
    }, []);

    const getData = async () => {
        try {
            let data = await axios.post(getReferralURL, {
                "phone_number": localStorage.getItem('mobileNumber'),
            }, {
                withCredentials: true,
                headers: header
            });
            if (data.data.status === "success") {
                setUserData(data.data);
                setWallet(data?.data?.Wallet_Amount.toFixed(2) ?? 0);
                setReferralLink(baseUrl + data.data?.Referral_Link ?? baseUrl);
                setDebitAmount(data?.data?.debitAmount.toFixed(2) ?? 0);
                setAvailableAmount(data?.data?.availableAmount.toFixed(2));
                setkycDashboardUrl(data?.data?.kyc_url);
            } else {
                console.log(data.data);
            }
        } catch (error) {
            console.log("Error fetching referral data:", error);
        }
    };

    const getProfile = async () => {
        try {
            const response = await axios.post(getProfileURL, {
                "phone_number": "",
            }, {
                withCredentials: true,
                headers: header
            });
            if (response.data.status === "success") {
                setFirstName(response?.data?.SubbrokerID ?? "");
                setLastName(response?.data?.commissionPercentage ?? "");
                setPhoneNumber(response?.data?.Phone_Number);
                const array1 = [];
                const array2 = [];
                const labels = [];
                const ledgers = Array.isArray(response.data['ledgers']) ? response.data['ledgers'] : [];
                ledgers.map((item) => {
                    labels.push(item.date_of_entry);
                    if (item['transtype'] === 'DEBIT') {
                        array2.push(item.amount);
                    } else {
                        array1.push(item.amount);
                    }
                });
            } else {
                console.log(response.data);
            }
        } catch (error) {
            console.log("Error fetching profile data:", error);
        }
    };

    const getSubbrokerIds = async () => {
        let ccodeVal = localStorage.getItem('firstName');
        let from_date = "01-04-2024";
        var currentDate = new Date();

        var day = currentDate.getDate();
        var month = currentDate.getMonth() + 1;
        var year = currentDate.getFullYear();

        if (day < 10) {
            day = '0' + day;
        }
        if (month < 10) {
            month = '0' + month;
        }

        var to_date = day + '-' + month + '-' + year;

        let body = {
            "subbroker_id": ccodeVal,
            "from_date": from_date,
            "to_date": to_date
        };
        try {
            const response = await axios.post(getRemeshireBrokerageURL, body);
            if (response.data.status) {
                let object = { "value": ccodeVal, "label": ccodeVal };
                const result = response.data.result[0];
                const ids = Object.keys(result).map(id => ({ value: id, label: id }));
                ids.push(object);
                setSubbrokerIds(ids);
            } else {
                console.log(response.data);
            }
        } catch (error) {
            console.log("Error fetching subbroker IDs:", error);
        }
    };

    const getHoldingsForPledgeAndUnpledge = async () => {
        try {
            let url = `${pledgeHoldingsUrl}client_id=${encodeURIComponent(selectedClientCode.value)}`;
            console.log(url);
            const response = await axios.get(url);
            if (response.data.status === "success") {
                const holdings = Array.isArray(response.data.data?.holdings) ? response.data.data.holdings : [];
                setClientHoldingsData(holdings);
            } else {
                console.error('API Response Failed');
            }
        } catch (error) {
            console.log("Error while fetching holdings list from API:", error);
        }
    };

    // Define pledgeShare function
    const pledgeShare = (symbol, ISIN, tokenId, quantity) => {
        console.log(`Pledge Share: Symbol=${symbol}, ISIN=${ISIN}, TokenId=${tokenId}, Quantity=${quantity}`);
        // Implement actual pledge logic here
    };

    // Define unpledgeShare function
    const unpledgeShare = (symbol, ISIN, tokenId, quantity) => {
        console.log(`Unpledge Share: Symbol=${symbol}, ISIN=${ISIN}, TokenId=${tokenId}, Quantity=${quantity}`);
        // Implement actual unpledge logic here
    };

    return (
        <div className='bg-gray-50'>
            <NavBar kycDashboardUrl={kycDashboardUrl} />
            <div className='mt-10 rounded-t-2xl bg-white p-4 container mx-auto' style={{ minHeight: '90vh' }}>
                <div className='row'>
                    <div className='col-md-12 col-xl-12'>
                        <p className='font-medium my-auto ml-2'>Pledge / Unpledge - {firstName ?? "..."}</p>
                    </div>
                </div>
                <div className='row mt-6 ml-2'>
                    <div className='col-md-2 col-lg-2 col-sm-12'>
                        <label>Client Code</label>
                        <Select options={subbrokerIds} value={selectedClientCode} onChange={setSelectedClientCode} placeholder="Select Code" isSearchable />
                    </div>
                    <div className='col-md-2 col-lg-2 col-sm-12'>
                        <button className='bg-green-500 mx-3 mt-8 p-2 text-white rounded font-medium text-sm' onClick={() => getHoldingsForPledgeAndUnpledge()}>Fetch Data</button>
                    </div>
                </div>
                <div className='p-2 mt-6'>
                    {
                        clientHoldingsData.length > 0 ?
                            <div className='lg:flex md:flex-auto sm:flex-none'>
                                <div className='flex-1'>
                                    <div className='overflow-x-auto'>
                                        <div className='table-responsive'>
                                            <div className='table mb-60'>
                                                <thead>
                                                    <tr>
                                                        <th>Symbol</th>
                                                        <th>ISIN</th>
                                                        <th>Pledge For Margin</th>
                                                        <th>Qty</th>
                                                        <th>Buy Avg</th>
                                                        <th>Buy Val</th>
                                                        <th>LTP</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {clientHoldingsData.map((item, index) => {
                                                        const PledgeQuantity = item.holding?.PledgeQuantity ?? 0;
                                                        const holdQty = item.holding?.HoldingQuantity ?? 0;

                                                        return (
                                                            <tr key={index}>
                                                                <td>{item.symbol}</td>
                                                                <td>{item.ISIN}</td>
                                                                <td><span className='w-30 inline-block'>{PledgeQuantity}</span> <Dropdown pledgeShare={pledgeShare} unpledgeShare={unpledgeShare} item={item} clientId={selectedClientCode.value} /></td>
                                                                <td>{item.holding?.HoldingQuantity ?? 0}</td>
                                                                <td>{item.holding?.BuyAvgPrice ?? 0}</td>
                                                                <td>{item.buyValue ?? 0}</td>
                                                                <td>{item.ticker?.LTP ?? 0}</td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <p>No data available.</p>
                    }
                </div>
            </div>
        </div>
    );
}
