import React, { useEffect, useState } from 'react'
import NavBar from '../components/NavBar'

import axios from 'axios'
import { UserCircleIcon } from '@heroicons/react/solid'
import { getReferralURL, getPaymentProfileURL, getProfileURL, updatePaymentProfileURL, updatePaymentURL, updateUserProfileURL } from '../utils/Urls'

import Loader from 'react-loader-spinner'
import { header } from '../utils/Request';

export default function Profile() {

    const [userProfile, setUserProfile] = useState()
    const [paymentProfile, setPaymentProfile] = useState()
    const [isProfileLoading, setProfileLoading] = useState(false)
    const [isPaymentLoading, setPaymentLoading] = useState(false)

    const [kycDashboardUrl, setkycDashboardUrl] = useState()

    const [isSecurityLoading, setSecurityLoading] = useState(false)
    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [phoneNumber, setPhoneNumber] = useState()
    const [email, setEmail] = useState()
    const [accountNumber, setAccountNumber] = useState()
    const [ifscCode, setIfscCode] = useState()
    const [bankName, setBankName] = useState()
    const [panNumber, setPanNumber] = useState()
    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")

    useEffect(() => {
        getData();
        getProfile()
        getPaymentProfile()
    }, [])

    const getData = async () => {
        try {
            let data = await axios.post(getReferralURL, {
                "phone_number": localStorage.getItem('mobileNumber'),
            }, {
                withCredentials: true,
                headers: header
            })
            if (data.data.status === "success") {
                setkycDashboardUrl(data?.data?.kyc_url)
            } else {
                console.log(data.data)
            }
        } catch (error) {
        }
    }

    const updateUserProfile = async () => {
        setProfileLoading(true)
        try {
            let data = await axios.post(updateUserProfileURL, {
                "name": firstName,
                "address": lastName,
                "email": email,

            })
            if (data.data.Status === "Success") {
                setProfileLoading(false)
                alert("User Profile Updated Successfully")
                window.location.reload()
            } else {
                setProfileLoading(false)
                alert(data.data.Message)
            }
        } catch (error) {
            alert(error)
            setProfileLoading(false)
        }

    }
    const updateSecurity = async () => {
        setSecurityLoading(true)
        try {
            let data = await axios.post(updateUserProfileURL, {
                "oldPassword": oldPassword,
                "newPassword": newPassword,
            })
            if (data.data.Status === "Success") {
                setSecurityLoading(false)
                alert("Password changed successfully")
                window.location.reload()
            } else {
                setSecurityLoading(false)
                alert(data.data.Message)
            }
        } catch (error) {
            alert(error)
            setSecurityLoading(false)
        }

    }



    const updatePaymentProfile = async () => {
        setPaymentLoading(true)
        try {
            let data = await axios.post(updatePaymentProfileURL, {
                "Account_Number": accountNumber,
                "IFSC_Code": ifscCode,
                "bankName": bankName,
                "Pan_Number": panNumber
            })
            if (data.data.Status === "Success") {
                setPaymentLoading(false)
                alert("Payment Updated Successfully")
                window.location.reload()
            } else {
                setPaymentLoading(false)
                alert(data.data.Message)
            }
        } catch (error) {
            alert(error)
            setPaymentLoading(false)
        }

    }

    const getPaymentProfile = async () => {
        axios.post(getPaymentProfileURL, {
            "phone_number": "",
        }).then((data) => {
            console.log(data)
            if (data.data.status === "success") {

                setPaymentProfile(data.data)
                setBankName(data?.data?.bankName ?? "")
                setAccountNumber(data?.data?.Account_Number ?? "")
                setPanNumber(data?.data?.Pan_Number ?? "")
                setIfscCode(data?.data?.IFSC_Code ?? "")

            } else {
                console.log(data.data)
            }
        }).catch((error) => {
            console.log(error)
            console.log("sess")

        })

    }


    const getProfile = async () => {

        axios.post(getProfileURL, {
            "phone_number": "",
        }, {
            withCredentials: true,
            headers: header
        }).then((data) => {
            if (data.data.status === "success") {
                console.log(data.data)
                setUserProfile(data?.data)
                setFirstName(data?.data?.First_Name ?? "")
                setLastName(data?.data?.Last_Name ?? "")
                setEmail(data?.data?.Email_Address ?? "")
                setPhoneNumber(data?.data?.Phone_Number)


            } else {
                console.log(data.data)
            }
        }).catch((error) => {

            console.log(error)
            console.log("sess")

        })
    }

    return (
        <div className='bg-gray-50'>
            <NavBar kycDashboardUrl={kycDashboardUrl} />
            <div className='mt-10 rounded-t-2xl bg-white  p-4 ' style={{ minHeight: '80vh' }}>

                <div className='flex'>
                    <UserCircleIcon className='h-12 text-gray-400' />
                    <p className='font-medium my-auto ml-2'>{phoneNumber ?? "..."}</p>

                </div>
                <div className='lg:flex sm:flex-none justify-evenly'>
                    <div className='p-4'>


                        <div className='p-4 mt-4'>
                            <p className='font-medium my-auto ml-2 text-lg'>Profile</p>
                            <p className='ml-2 text-gray-400 text-xs'>The information can be edited</p>

                            <div className='lg:flex sm:flex-none mx-2 mt-4'>
                                <div className='mx-1'>
                                    {/* <p className='text-sm text-gray-400 mb-1'>First Name</p> */}
                                    <div className='border text-center p-2'>
                                        <input placeholder='First Name' className='outline-none border-none text-sm' type='text' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                    </div>
                                </div>

                                <div className='mx-1'>
                                    {/* <p className='text-sm text-gray-400 mb-1'>Last Name</p> */}
                                    <div className='border text-center p-2'>
                                        <input placeholder='Address' className='outline-none border-none text-sm text-left' type='text' value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                    </div>
                                </div>



                            </div>

                            <div className='lg:flex sm:flex-none mx-2 mt-4'>
                                <div className='mx-1'>
                                    {/* <p className='text-sm text-gray-400 mb-1'>Mobile Number</p> */}
                                    <div className='border text-center p-2'>
                                        <input placeholder='PhoneNumber' className='outline-none border-none text-sm bg-white' type='text' disabled value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                                    </div>
                                </div>

                                <div className='mx-1'>
                                    {/* <p className='text-sm text-gray-400 mb-1'>Email Address</p> */}
                                    <div className='border text-center p-2'>
                                        <input placeholder='Email Address' className='outline-none border-none text-sm' type='text' value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                </div>




                            </div>
                            <div className='flex justify-end'>
                                {isProfileLoading ?
                                    <Loader
                                        type="ThreeDots"
                                        color="#00e676"
                                        height={60}
                                        width={60}//3 secs
                                    />
                                    :
                                    <button className='bg-green-500 mx-3 mt-3 p-2 text-white rounded font-medium text-sm ' onClick={() => updateUserProfile()}>Update Profile</button>

                                }
                            </div>
                        </div>



                    </div>

                    <div className=' p-4 mt-6'>

                        <div className='p-4'>
                            <p className='font-medium my-auto ml-2 text-lg'>Payment Details</p>
                            <p className='ml-2 text-gray-400 text-xs'>The information can be edited</p>

                            <div className='lg:flex sm:flex-none mx-2 mt-4'>
                                <div className='mx-1'>
                                    <p className='text-sm text-gray-400 mb-1'>Account Number</p>
                                    <div className='border text-center p-2'>
                                        <input placeholder='AccountNumber' className='outline-none border-none text-sm' type='text' value={accountNumber} onChange={(e) => setAccountNumber(e.target.value)} />
                                    </div>
                                </div>

                                <div className='mx-1'>
                                    <p className='text-sm text-gray-400 mb-1'>IFSC CODE</p>
                                    <div className='border text-center p-2'>
                                        <input placeholder='IFSC CODE' className='outline-none border-none text-sm' type='text' value={ifscCode} onChange={(e) => setIfscCode(e.target.value)} />
                                    </div>
                                </div>


                            </div>


                            <div className='lg:flex sm:flex-none mx-2 mt-2'>
                                <div className='mx-1'>
                                    <p className='text-sm text-gray-400 mb-1'>Bank Name</p>
                                    <div className='border text-center p-2'>
                                        <input placeholder='Bank Name' className='outline-none border-none text-sm' type='text' value={bankName} onChange={(e) => setBankName(e.target.value)} />
                                    </div>
                                </div>

                                <div className='mx-1'>
                                    <p className='text-sm text-gray-400 mb-1'>Pan Number</p>
                                    <div className='border text-center p-2'>
                                        <input placeholder='Pan Number' className='outline-none border-none text-sm' type='text' value={panNumber} onChange={(e) => setPanNumber(e.target.value)} />
                                    </div>
                                </div>


                            </div>



                            <div className='flex justify-end'>
                                {isPaymentLoading ?
                                    <Loader
                                        type="ThreeDots"
                                        color="#00e676"
                                        height={60}
                                        width={60}//3 secs
                                    />
                                    :
                                    <button className='bg-green-500 mx-3 mt-3 p-2 text-white rounded font-medium text-sm ' onClick={() => updatePaymentProfile()}>Update payment</button>

                                }
                            </div>
                        </div>


                    </div>

                    <div className=' p-4 mt-6'>


                    </div>
                </div>

                <div>

                </div>
            </div>

        </div>

    )
}
