import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { header } from '../utils/Request';
import { pledgeUnpledgeUrl } from '../utils/Urls';

const UnpledgeModal = ({ show, handleClose, symbol, tokenId, ISIN, overallQty, clientId, pledgeQuantity, setUnpledgeQuantity, handleUnpledge }) => {

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [unpledgeQty, setUnPledgeQty] = useState('');

    const requestUnPledgeShared = async () => {
        try {
            if (unpledgeQty <= pledgeQuantity) {
                if (unpledgeQty != '') {
                    let url = `${pledgeUnpledgeUrl}client_id=${encodeURIComponent(clientId)}&token_id=${encodeURIComponent(tokenId)}&pledge_qty=${encodeURIComponent(unpledgeQty)}&event=UNPLEDGE&source=SUBBROKER&isin=${encodeURIComponent(ISIN)}`
                    const response = await axios.get(url);
                    const data = response.data;
                    if (data.status === 'success') {
                        setSuccessMessage(data.message)
                        setTimeout(() => {
                            window.location.reload();
                        }, 4000);
                    } else {
                        setErrorMessage(data.message);
                        setTimeout(() => {
                            window.location.reload();
                        }, 4000);
                    }
                } else {
                    setErrorMessage('Please enter Unpledge Qty value...');
                }
            } else {
                setErrorMessage('Unpledge Qty Value has to be lessthan or equal to Pledge Qty...');
            }
        } catch (error) {
            setErrorMessage('Failed to Unpledge Shares : ', error);
        }
    }

    const showHideClassName = show ? "modal display-block" : "modal display-none";

    return (
        <div className={showHideClassName} tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Unpledge Shares</h5>
                        <button type="button" className="close" onClick={handleClose} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className='row'>
                            <div className="col-md-6 col-lg-6 col-xl-12 mt-3">
                                <div className="form-group">
                                    <label>Symbol</label>
                                    <input type="text" className="form-control" value={symbol} disabled />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-xl-12 mt-3">
                                <div className="form-group">
                                    <label>Token ID</label>
                                    <input type="text" className="form-control" value={tokenId} disabled />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-xl-12 mt-3">
                                <div className="form-group">
                                    <label>ISIN</label>
                                    <input type="text" className="form-control" value={ISIN} disabled />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-xl-12 mt-3">
                                <div className="form-group">
                                    <label>Pledged Quantity</label>
                                    <input type="text" className="form-control" value={pledgeQuantity} disabled />
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-12 col-xl-12 mt-3">
                                <div className="form-group">
                                    <label>Unpledge Quantity</label>
                                    <input type="number" className="form-control" value={unpledgeQty} onChange={(e) => setUnPledgeQty(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-12 col-xl-12 text-center">
                                <button className='bg-green-500 mx-3 mt-8 p-2 text-white rounded font-medium text-sm' onClick={requestUnPledgeShared} >Submit</button>
                                <p id="payoutRequestSuccessTxt" style={{ color: '#34a853' }} className="mt-1">{successMessage}</p>
                                <p id="payoutRequestErrorTxt" style={{ color: '#EA5455' }} className="mt-1">{errorMessage}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UnpledgeModal;
