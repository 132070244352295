import React, { useState } from 'react';
import PledgeModal from './PledgeModal';
import UnpledgeModal from './UnpledgeModal';

const Dropdown = ({ pledgeShare, unpledgeShare, item, clientId }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [showPledgeModal, setShowPledgeModal] = useState(false);
    const [showUnpledgeModal, setShowUnpledgeModal] = useState(false);
    const [pledgeQuantity, setPledgeQuantity] = useState('');
    const [unpledgeQuantity, setUnpledgeQuantity] = useState('');

    const toggleDropdown = () => setIsOpen(!isOpen);

    const PledgeQuantity = item.holding?.PledgeQuantity ?? 0;
    const holdQty = item.holding?.HoldingQuantity ?? 0;

    const handlePledge = () => {
        pledgeShare(item.symbol, item.ISIN, item.tokenId, pledgeQuantity, clientId);
        setShowPledgeModal(false);
    };

    const handleUnpledge = () => {
        unpledgeShare(item.symbol, item.ISIN, item.tokenId, unpledgeQuantity);
        setShowUnpledgeModal(false);
    };

    return (
        <div className="dropdown">
            <button className="dropdown-toggle" onClick={toggleDropdown}></button>
            <div className={`dropdown-menu ${isOpen ? 'show' : ''}`}>
                {PledgeQuantity === 0 ? (
                    <a className="dropdown-item" href="#!" onClick={() => setShowPledgeModal(true)}>Pledge For Margin</a>
                ) : PledgeQuantity === holdQty ? (
                    <>
                        <a className="dropdown-item" href="#!" onClick={() => setShowUnpledgeModal(true)}>Unpledge</a>
                        {/* <i className="fa fa-lock" aria-hidden="true"></i>
                        <span>{PledgeQuantity}</span> */}
                    </>
                ) : PledgeQuantity >= 1 ? (
                    <>
                        <a className="dropdown-item" href="#!" onClick={() => setShowUnpledgeModal(true)}>Unpledge</a>
                        <a className="dropdown-item" href="#!" onClick={() => setShowPledgeModal(true)}>Pledge For Margin</a>
                    </>
                ) : null}
            </div>
            <PledgeModal
                show={showPledgeModal}
                handleClose={() => setShowPledgeModal(false)}
                symbol={item.symbol}
                tokenId={item.tokenId}
                ISIN={item.ISIN}
                overallQty={item.holding?.HoldingQuantity ?? 0}
                clientId={clientId}
                pledgeQuantity={pledgeQuantity}
                setPledgeQuantity={setPledgeQuantity}
                handlePledge={handlePledge}
            />
            <UnpledgeModal
                show={showUnpledgeModal}
                handleClose={() => setShowUnpledgeModal(false)}
                symbol={item.symbol}
                tokenId={item.tokenId}
                ISIN={item.ISIN}
                overallQty={item.holding?.HoldingQuantity ?? 0}
                clientId={clientId}
                pledgeQuantity={PledgeQuantity}
                setUnpledgeQuantity={setUnpledgeQuantity}
                handleUnpledge={handleUnpledge}
            />
        </div>
    );
};

export default Dropdown;



// // Dropdown.js
// import React, { useState } from 'react';

// const Dropdown = ({ pledgeShare, unpledgeShare, item }) => {
//     const [isOpen, setIsOpen] = useState(false);

//     const toggleDropdown = () => setIsOpen(!isOpen);

//     return (
//         <div className="dropdown">
//             <button className="dropdown-toggle" onClick={toggleDropdown}>
//             </button>
//             <div className={`dropdown-menu ${isOpen ? 'show' : ''}`}>
//                 {item.holding?.PledgeQuantity === 0 ? (
//                     <a className="dropdown-item" href="#!" onClick={() => pledgeShare(item.symbol, item.ISIN, item.tokenId, item.holding.HoldingQuantity)}>Pledge For Margin</a>
//                 ) : item.holding?.PledgeQuantity === item.holding.HoldingQuantity ? (
//                     <>
//                         <a className="dropdown-item" href="#!" onClick={() => unpledgeShare(item.symbol, item.ISIN, item.tokenId, item.holding.PledgeQuantity)}>Unpledge</a>
//                         <i className="fa fa-lock" aria-hidden="true"></i>
//                         <span>{item.holding?.PledgeQuantity}</span>
//                     </>
//                 ) : item.holding?.PledgeQuantity >= 1 ? (
//                     <>
//                         <a className="dropdown-item" href="#!" onClick={() => unpledgeShare(item.symbol, item.ISIN, item.tokenId, item.holding.PledgeQuantity)}>Unpledge</a>
//                         <a className="dropdown-item" href="#!" onClick={() => pledgeShare(item.symbol, item.ISIN, item.tokenId, item.holding.HoldingQuantity)}>Pledge For Margin</a>
//                     </>
//                 ) : null}
//             </div>
//         </div>
//     );
// };

// export default Dropdown;
