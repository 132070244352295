import React, { useEffect, useState } from 'react';
import NavBar from '../components/NavBar';
import ClientPayoutRequestModal from '../components/ClientPayoutRequestModal';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { getSubbrokerContactsURL, neuronGetPayoutDetailsUrl, synapsesGetUserUrl, getReferralURL, synapsesTokenUrl, synapsesTokenUser, synapsesTokenPass, synapsesKycFilter } from '../utils/Urls';
import { header } from '../utils/Request';

export default function ContactView() {
    const [kycDashboardUrl, setkycDashboardUrl] = useState()

    const { clientId: clientIdParam } = useParams();
    const [clientId, setClientId] = useState(null);
    const [clientMobile, setClientMobile] = useState(null);
    const [clientData, setClientData] = useState([]);

    const [userData, setUserData] = useState([]);
    const [contactData, setContactData] = useState([]);
    const [nomineeData, setNomineeData] = useState([]);
    const [bankData, setBankData] = useState([]);
    const [brokerageData, setBrokerageData] = useState([]);
    const [fatcaData, setFatcaData] = useState([]);
    const [clientPayoutDetailsData, setClientPayoutDetailsData] = useState([]);

    const [activeTab, setActiveTab] = useState(0);

    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    useEffect(() => {
        setClientId(clientIdParam);
    }, [clientIdParam]);

    useEffect(() => {
        if (clientId) {
            intiateClientDetails();
        }
    }, [clientId]);

    useEffect(() => {
        if (clientId) {
            intiateClientPayoutDetails();
        }
    }, [clientId]);

    useEffect(() => {
        getData();
        intiateUserDetails();
    })

    const getData = async () => {
        try {
            let data = await axios.post(getReferralURL, {
                "phone_number": localStorage.getItem('mobileNumber'),
            }, {
                withCredentials: true,
                headers: header
            })
            if (data.data.status === "success") {
                setkycDashboardUrl(data?.data?.kyc_url)
            } else {
                console.log(data.data)
            }
        } catch (error) {
        }
    }

    const getSynapsesToken = async () => {
        try {
            let body = {
                "username": synapsesTokenUser,
                "password": synapsesTokenPass
            };
            let response = await axios.post(synapsesTokenUrl, body);
            let token = response.data.access;
            return token;
        } catch (error) {
            // console.error('Error fetching token:', error);
            throw error;
        }
    };


    const intiateClientDetails = async () => {
        try {
            const synapsesToken = await getSynapsesToken();
            let synpheaders = {
                'Authorization': `Bearer ${synapsesToken}`,
                'Content-Type': 'application/json'
            };
            let body = {
                "client_id": clientId,
                "client_name": "",
                "branch": "",
                "state": ""
            };
            const response = await axios.post(synapsesKycFilter, body, { headers: synpheaders });
            if (response.status === 200) {
                let data = response.data.ClientDetails[0];
                setClientData(data);
                setClientMobile(data.mobile);
            } else {
                alert(response.message);
            }
        } catch (error) {
            console.error('Error fetching position data:', error);
        }
    };

    const intiateClientPayoutDetails = async () => {
        try {
            let url = `${neuronGetPayoutDetailsUrl}clientCode=${encodeURIComponent(clientId)}`;
            console.log(url)
            const response = await axios.get(url)
            if (response.data.status === "success") {  // Access the status from response.data
                const data = response.data.data;  // Access the data array
                setClientPayoutDetailsData(data);
            } else {
                console.error('API Response Failed');
            }
        } catch (error) {
            console.error('Error fetching Client Payout Details data:', error);
        }
    }

    const intiateUserDetails = async () => {
        try {
            const synapsesToken = await getSynapsesToken();
            let synpheaders = {
                'Authorization': `Bearer ${synapsesToken}`,
                'Content-Type': 'application/json'
            };
            let url = `${synapsesGetUserUrl}clientNumber=${encodeURIComponent(clientMobile)}`;
            const response = await axios.get(url, { headers: synpheaders });
            if (response.status === 200) {
                const data = response.data;
                setUserData(data.user || {});
                setContactData(data.contact_info[0] || []);
                setNomineeData(data.nominee_info[0] || []);
                setBankData(data.bank_info[0] || []);
                setBrokerageData(data.brokerage_info || []);
                setFatcaData(data.fatca || {});
            }
        } catch (error) {
            console.error('Error fetching Bank and Brokerage Details data:', error);
        }
    };

    return (
        <div className='bg-gray-50'>
            <NavBar kycDashboardUrl={kycDashboardUrl} />
            <div className='mt-10 rounded-t-2xl bg-white p-4 container mx-auto' style={{ minHeight: '25vh' }}>
                <div className='flex justify-between'>
                    <div className='my-auto'>
                        <img src={"https://adityatrading.in/static/images/user.png"} />
                    </div>
                    <div className='my-auto'>
                        <p className='color-medium'>{clientData.client_name || '--'}</p>
                        <p className='color-medium'>{clientData.email || '--'}</p>
                        <button className='bg-red-500 mt-3 p-2 text-white btn-outline-danger font-medium text-sm' onClick={openModal}>Request Payout</button>
                    </div>
                    <ClientPayoutRequestModal show={showModal} handleClose={closeModal} clientId={clientId} clientName={clientData.client_name} accountNo={bankData.account_number} accountName={bankData.account_holder_name} />
                    <div className="my-auto">
                        <h4>User Information</h4>
                        <div className="d-flex flex-wrap pl-4">
                            <div className="user-info-title">
                                <i className="fa fa-info-circle mr-1 color-medium" aria-hidden="true"></i>
                                <span className="card-text user-info-title pl-4 font-weight-bold mb-0">Client ID - {clientData.client_id || '--'}</span>
                            </div>
                        </div>
                        <div className="d-flex flex-wrap pl-4">
                            <div className="user-info-title">
                                <i className="fa fa-user-o mr-1 color-medium" aria-hidden="true"></i>
                                <span className="card-text user-info-title pl-4 font-weight-bold mb-0">Username - {clientData.client_name || '--'}</span>
                            </div>
                        </div>
                        <div className="d-flex flex-wrap pl-4">
                            <div className="user-info-title">
                                <i className="fa fa-phone mr-1 color-medium" aria-hidden="true"></i>
                                <span className="card-text user-info-title pl-4 font-weight-bold mb-0">Contact Number - {clientData.mobile || '--'}</span>
                            </div>
                        </div>
                        <div className="d-flex flex-wrap pl-4">
                            <div className="user-info-title">
                                <i className="fa fa-check mr-1 color-medium" aria-hidden="true"></i>
                                <span className="card-text user-info-title pl-4 font-weight-bold mb-0">Bo ID - {clientData.email || '--'}</span>
                            </div>
                        </div>

                    </div>
                    <div className="my-auto">
                        <div className="d-flex flex-wrap pl-4">
                            <div className="user-info-title">
                                <i className="fa fa-flag-o mr-1 color-medium" aria-hidden="true"></i>
                                <span className="card-text user-info-title pl-4 font-weight-bold mb-0">Agreement Date - {clientData.agreement_date || '--'}</span>
                            </div>
                        </div>
                        <div className="d-flex flex-wrap pl-4">
                            <div className="user-info-title">
                                <i className="fa fa-globe mr-1 color-medium" aria-hidden="true"></i>
                                <span className="card-text user-info-title pl-4 font-weight-bold mb-0">State - {clientData.state || '--'}</span>
                            </div>
                        </div>
                        <div className="d-flex flex-wrap pl-4">
                            <div className="user-info-title">
                                <i className="fa fa-tag mr-1 color-medium" aria-hidden="true"></i>
                                <span className="card-text user-info-title pl-4 font-weight-bold mb-0">Branch - {clientData.branch || '--'}</span>
                            </div>
                        </div>
                        <div className="d-flex flex-wrap pl-4">
                            <div className="user-info-title">
                                <i className="fa fa-star-o mr-1 color-medium" aria-hidden="true"></i>
                                <span className="card-text user-info-title pl-4 font-weight-bold mb-0">Pan Card - {clientData.pan_card || '--'}</span>
                            </div>
                        </div>
                        <div className="d-flex flex-wrap pl-4">
                            <div className="user-info-title">
                                <i className="fa fa-calendar-o mr-1 color-medium" aria-hidden="true"></i>
                                <span className="card-text user-info-title pl-4 font-weight-bold mb-0">DOB - {clientData.date_of_birth || '--'}</span>
                            </div>
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className="d-none d-flex flex-wrap pl-4">
                            <div className="user-info-title">
                                <i className="fa fa-flag-o mr-1 color-medium" aria-hidden="true"></i>
                                <span className="card-text user-info-title pl-4 font-weight-bold mb-0">Agreement</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-10 rounded-t-2xl bg-white p-4 container mx-auto' style={{ minHeight: '60vh' }}>
                <div className="tab-list">
                    <button onClick={() => handleTabClick(0)} className={activeTab === 0 ? 'active' : ''}>User Details</button>
                    <button onClick={() => handleTabClick(1)} className={activeTab === 1 ? 'active' : ''}>Contact Info</button>
                    <button onClick={() => handleTabClick(2)} className={activeTab === 2 ? 'active' : ''}>Brokerage Info</button>
                    <button onClick={() => handleTabClick(3)} className={activeTab === 3 ? 'active' : ''}>Bank Info</button>
                    <button onClick={() => handleTabClick(4)} className={activeTab === 4 ? 'active' : ''}>FATCA</button>
                    <button onClick={() => handleTabClick(5)} className={activeTab === 5 ? 'active' : ''}>Payout Requests</button>
                </div>
                <div className="tab-content">
                    {activeTab === 0 &&
                        <div className='row ml-2'>
                            <div className='col-md-2 col-lg-2 col-sm-12 cborder'>
                                <p className="mb-0 c-r">Last Trade Date</p>
                                <p className="font-12">{userData.last_trade_date || '--'}</p>
                            </div>
                            <div className='col-md-2 col-lg-2 col-sm-12 cborder'>
                                <p className="mb-0 c-g">Client Code</p>
                                <p className="font-12">{userData.client_code || '--'}</p>
                            </div>

                            <div className='col-md-2 col-lg-2 col-sm-12 cborder'>
                                <p className="mb-0 c-vo">Client Name</p>
                                <p className="font-12">{userData.client_name || '--'}</p>
                            </div>
                            <div className='col-md-2 col-lg-2 col-sm-12 cborder'>
                                <p className="mb-0 c-p">Contact Number</p>
                                <p className="font-12">{userData.mobile || '--'}</p>
                            </div>
                            <div className='col-md-2 col-lg-2 col-sm-12 cborder'>
                                <p className="mb-0 c-v">Email</p>
                                <p className="font-12">{userData.email || '--'}</p>
                            </div>
                            <div className='col-md-2 col-lg-2 col-sm-12 cborder'>
                                <p className="mb-0 c-r">PAN</p>
                                <p className="font-12">{userData.pan_id || '--'}</p>
                            </div>
                            <div className='col-md-2 col-lg-2 col-sm-12 cborder'>
                                <p className="mb-0 c-vo">Active</p>
                                <p className="font-12">{userData.active || '--'}</p>
                            </div>
                            <div className='col-md-2 col-lg-2 col-sm-12 cborder'>
                                <p className="mb-0 c-vi">Staff</p>
                                <p className="font-12">{userData.staff || '--'}</p>
                            </div>
                            <div className='col-md-2 col-lg-2 col-sm-12 cborder'>
                                <p className="mb-0 c-lp">Admin</p>
                                <p className="font-12">{userData.admin || '--'}</p>
                            </div>
                            <div className='col-md-2 col-lg-2 col-sm-12 cborder'>
                                <p className="mb-0 c-b">Joined Date</p>
                                <p className="font-12">{userData.date_joined || '--'}</p>
                            </div>
                            <div className='col-md-2 col-lg-2 col-sm-12 cborder'>
                                <p className="mb-0 c-g">Dealer Code</p>
                                <p className="font-12">{userData.dealer_code || '--'}</p>
                            </div>
                            <div className='col-md-2 col-lg-2 col-sm-12 cborder'>
                                <p className="mb-0 c-p">Dealer Name</p>
                                <p className="font-12">{userData.dealer_name || '--'}</p>
                            </div>
                            <div className='col-md-2 col-lg-2 col-sm-12 cborder'>
                                <p className="mb-0 c-g">RO Code</p>
                                <p className="font-12">{userData.relationship_officer_code || '--'}</p>
                            </div>
                            <div className='col-md-2 col-lg-2 col-sm-12 cborder'>
                                <p className="mb-0 c-v">RO Name</p>
                                <p className="font-12">{userData.relationship_officer_name || '--'}</p>
                            </div>
                            <div className='col-md-2 col-lg-2 col-sm-12 cborder'>
                                <p className="mb-0 c-vo">RM Code</p>
                                <p className="font-12">{userData.relationship_manager_code || '--'}</p>
                            </div>
                            <div className='col-md-2 col-lg-2 col-sm-12 cborder'>
                                <p className="mb-0 c-b">RM Name</p>
                                <p className="font-12">{userData.relationship_manager_name || '--'}</p>
                            </div>
                        </div>
                    }
                    {activeTab === 1 &&
                        <div className='row ml-2'>
                            <div className="col-md-2 cborder">
                                <p className="mb-0 c-r">Preferred Communication</p>
                                <p className="font-12">{contactData.preferred_communication || '--'}</p>
                            </div>
                            <div className="col-md-2 cborder">
                                <p className="mb-0 c-g">Address1</p>
                                <p className="font-12">{contactData.address1 || '--'}</p>
                            </div>
                            <div className="col-md-2 cborder">
                                <p className="mb-0 c-vo">Address2</p>
                                <p className="font-12">{contactData.address2 || '--'}</p>
                            </div>
                            <div className="col-md-2 cborder">
                                <p className="mb-0 c-p">Directions</p>
                                <p className="font-12">{contactData.directions || '--'}</p>
                            </div>
                            <div className="col-md-2 cborder">
                                <p className="mb-0 c-v">Postal Code</p>
                                <p className="font-12">{contactData.postal_code || '--'}</p>
                            </div>
                            <div className="col-md-2 cborder">
                                <p className="mb-0 c-r">Country Geo</p>
                                <p className="font-12">{contactData.country_geo || '--'}</p>
                            </div>
                            <div className="col-md-2 cborder">
                                <p className="mb-0 c-vo">State Province</p>
                                <p className="font-12">{contactData.state_province_geo || '--'}</p>
                            </div>
                            <div className="col-md-2 cborder">
                                <p className="mb-0 c-vi">City</p>
                                <p className="font-12">{contactData.city || '--'}</p>
                            </div>
                            <div className="col-md-2 cborder">
                                <p className="mb-0 c-lp">ISD Code</p>
                                <p className="font-12">{contactData.isd_code || '--'}</p>
                            </div>
                            <div className="col-md-2 cborder">
                                <p className="mb-0 c-b">Phone</p>
                                <p className="font-12">{contactData.phone1 || '--'}</p>
                            </div>
                            <div className="col-md-2 cborder">
                                <p className="mb-0 c-g">Email</p>
                                <p className="font-12">{contactData.email1 || '--'}</p>
                            </div>
                        </div>
                    }
                    {activeTab === 2 &&
                        <div>
                            {brokerageData?.map((item) => {
                                return (
                                    <div className='row ml-2'>
                                        <div className="col-md-2 cborder">
                                            <p className="mb-0 c-r">Client Exchange</p>
                                            <p className="font-12">{item?.client_exchange}</p>
                                        </div>
                                        <div className="col-md-2 cborder">
                                            <p className="mb-0 c-g">Brok min</p>
                                            <p className="font-12">{item?.brok_min__brokerage_one_side_min}</p>
                                        </div>
                                        <div className="col-md-2 cborder">
                                            <p className="mb-0 c-vo">Brok Percent</p>
                                            <p className="font-12">{item?.brok_percent__brokerage_one_side_percent}</p>
                                        </div>
                                        <div className="col-md-2 cborder">
                                            <p className="mb-0 c-p">Brok Percent MCX</p>
                                            <p className="font-12">{item?.brok_percent_mcx}</p>
                                        </div>
                                        <div className="col-md-2 cborder">
                                            <p className="mb-0 c-v">From Date</p>
                                            <p className="font-12">{item?.from_date}</p>
                                        </div>
                                        <div className="col-md-2 cborder">
                                            <p className="mb-0 c-r">Through Date</p>
                                            <p className="font-12">{item?.thru_date}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    }
                    {activeTab === 3 &&
                        <div className='row ml-2'>
                            <div className="col-md-2 cborder">
                                <p className="mb-0 c-r">Bank Name</p>
                                <p className="font-12">{bankData.bank_name || '--'}</p>
                            </div>
                            <div className="col-md-2 cborder">
                                <p className="mb-0 c-g">Account Holder Name</p>
                                <p className="font-12">{bankData.account_holder_name || '--'}</p>
                            </div>
                            <div className="col-md-2 cborder">
                                <p className="mb-0 c-vo">Account Number</p>
                                <p className="font-12">{bankData.account_number || '--'}</p>
                            </div>
                            <div className="col-md-2 cborder">
                                <p className="mb-0 c-p">Account Type</p>
                                <p className="font-12">{bankData.account_type || '--'}</p>
                            </div>
                            <div className="col-md-2 cborder">
                                <p className="mb-0 c-v">ISFC Code</p>
                                <p className="font-12">{bankData.ifsc_code || '--'}</p>
                            </div>
                            <div className="col-md-2 cborder">
                                <p className="mb-0 c-r">MICR Code</p>
                                <p className="font-12">{bankData.micr_code || '--'}</p>
                            </div>
                            <div className="col-md-2 cborder">
                                <p className="mb-0 c-vo">Branch Name</p>
                                <p className="font-12">{bankData.branch_name || '--'}</p>
                            </div>
                        </div>
                    }
                    {activeTab === 4 &&
                        <div className='row ml-2'>
                            <div className="col-md-2 cborder">
                                <p className="mb-0 c-r">Annual Income</p>
                                <p className="font-12">{fatcaData.annual_income || '--'}</p>
                            </div>
                            <div className="col-md-2 cborder">
                                <p className="mb-0 c-g">Type of Business</p>
                                <p className="font-12">{fatcaData.nature_of_business || '--'}</p>
                            </div>
                            <div className="col-md-2 cborder">
                                <p className="mb-0 c-vo">Politically exposed</p>
                                <p className="font-12">{fatcaData.client_politically_exposed || '--'}</p>
                            </div>
                            <div className="col-md-2 cborder">
                                <p className="mb-0 c-p">Experience in trading</p>
                                <p className="font-12">{fatcaData.experience_in_trading || '--'}</p>
                            </div>
                            <div className="col-md-2 cborder">
                                <p className="mb-0 c-v">Source of wealth</p>
                                <p className="font-12">{fatcaData.source_of_wealth || '--'}</p>
                            </div>
                            <div className="col-md-2 cborder">
                                <p className="mb-0 c-r">Source of wealth please specify</p>
                                <p className="font-12">{fatcaData.source_of_wealth_please_specify || '--'}</p>
                            </div>
                            <div className="col-md-2 cborder">
                                <p className="mb-0 c-vo">Place of birth</p>
                                <p className="font-12">{fatcaData.place_of_birth || '--'}</p>
                            </div>
                            <div className="col-md-2 cborder">
                                <p className="mb-0 c-vi">Country of birth</p>
                                <p className="font-12">{fatcaData.country_of_birth || '--'}</p>
                            </div>
                            <div className="col-md-2 cborder">
                                <p className="mb-0 c-lp">Tax residency</p>
                                <p className="font-12">{fatcaData.tax_residency || '--'}</p>
                            </div>
                            <div className="col-md-2 cborder">
                                <p className="mb-0 c-b">Tax residency status</p>
                                <p className="font-12">{fatcaData.tax_residency_status || '--'}</p>
                            </div>
                        </div>
                    }
                    {activeTab === 5 &&
                        <div className='row ml-2'>
                            {
                                clientPayoutDetailsData.length !== 0 ?
                                    <div className='lg:flex md:flex-auto sm:flex-none'>
                                        <div className='flex-1'>
                                            <div className='overflow-x-auto'>
                                                <div className='table-responsive'>
                                                    <div className='table'>
                                                        <thead>
                                                            <tr>
                                                                <th>Client Code</th>
                                                                <th>Request Id</th>
                                                                <th>Amount</th>
                                                                <th>Date</th>
                                                                <th>Reference No</th>
                                                                <th>Status</th>
                                                                <th>Reason</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {clientPayoutDetailsData?.map((item) => {
                                                                let statusDisplay;
                                                                let statusClass;
                                                                switch (item?.status) {
                                                                    case 'success':
                                                                        statusDisplay = 'Initiated';
                                                                        statusClass = 'c-v';
                                                                        break;
                                                                    case 'completed':
                                                                        statusDisplay = 'Completed';
                                                                        statusClass = 'c-g';
                                                                        break;
                                                                    case 'cancelled':
                                                                        statusDisplay = 'Payout Cancelled';
                                                                        statusClass = 'c-lp';
                                                                        break;
                                                                    case 'rejected':
                                                                        statusDisplay = 'Rejected';
                                                                        statusClass = 'c-r';
                                                                        break;
                                                                    default:
                                                                        statusDisplay = item?.status;
                                                                        statusClass = '';
                                                                }
                                                                return (
                                                                    <tr>
                                                                        <td>{item?.client_code}</td>
                                                                        <td>{item?.request_id}</td>
                                                                        <td>{item?.transaction_amount}</td>
                                                                        <td>{item?.requested_time}</td>
                                                                        <td>{item?.reference_no !== null ? item?.reference_no : "--"}</td>
                                                                        <td><span className={statusClass}>{statusDisplay}</span></td>
                                                                        <td>{item?.payable_reason !== "" ? item?.payable_reason : "--"}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : (
                                        <div className='text-center mt-4'>No data found</div>
                                    )
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
