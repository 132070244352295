import React,{useEffect, useState} from 'react';
import Modal from 'react-modal';

import { XIcon } from '@heroicons/react/solid'
import { updatePaymentURL } from '../utils/Urls';
import Loader from 'react-loader-spinner';
import axios from 'axios';
import toast from 'react-hot-toast';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};


export function WithdrawModal({amount,debitAmount,availableAmount}) {

  const [modalIsOpen, setIsOpen] = React.useState(false);
const [withdrawAmount ,setWithdraAmount] = useState()
// const [availableBalance ,setAvailableBalance] = useState()
const [isLoading,setLoading] = useState(false)

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    
  }

  function closeModal() {
    setIsOpen(false);
  }

  const validateWithdraw = () =>{
    console.log("starting withdraw")
if(availableAmount > 0){
  if(withdrawAmount < availableAmount){
    setLoading(true)
    withdraw()
}else{
    toast.error("Withdraw amount should be less than wallet balance")
}
}else{
  toast.error("Withdraw amount should be more than zero")
}
  }

  const withdraw = async () => {
    console.log("withdraw validated")
    try {
        let data = await axios.post(updatePaymentURL, {
          "Amount":withdrawAmount
        })
        console.log(data)
        if (data.data.status === "success") {
            setLoading(false)
            toast.success("Payment initiated successfully")
            window.location.reload()
        
        } else {
            setLoading(false)
            toast.error(data.data.Message)
          
        }
      } catch (error) {
          setLoading(false)
          toast.error(error)
      }
  }


  return (
    <div>
      
      <button className='bg-green-500 rounded text-white px-2 py-1 mt-2' onClick={()=>openModal()}>Withdraw</button>                  
          
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
          <div className='p-3'>
          <div className='flex justify-end'>
          <XIcon className='h-6' onClick={()=>closeModal()}/>
          </div>
              <p className='font-bold text-lg' >Withdraw Money</p>
              <p className='text-sm' ><span className='text-red-400'>Note :- </span>For TDS will be deducting 5%</p>
              <div className='flex p-2 border mt-4 justify-between'>
                       
                 <input placeholder='0' className='outline-none border-none ml-2' type='number' value={withdrawAmount} onChange={(e)=>setWithdraAmount(e.target.value)}    />

                 {isLoading ? 
                <Loader
                type="ThreeDots"
                color="#00e676"
                height={60}
                width={60}//3 secs
              />
                :
                <button className='mr-2 bg-green-400 text-white p-2' onClick={()=>validateWithdraw()}>Withdraw</button>
                        
                }
</div>

                         <p className='mt-2 text-sm'>Your clients having debitAmount {debitAmount ?? 0} </p>
                         <p className='mt-1 text-sm'>Available withdraw amount {availableAmount ?? 0} </p>
                         
          </div>
          
               </Modal>
    </div>
  );
}